<template>
  <credit-header/>
  <div class="container credit-procedure">
    <div class="header_history_link" style="display: flex; justify-content: flex-start !important;">
      <router-link to="/credit-card-service" class="header_history_url_main">Кредит хизматлари</router-link>&nbsp;&nbsp;/&nbsp;&nbsp;
      <span class="header_history_url">Кредит карта бериш тартиби</span>
    </div>
    <div class="micro_credit_law">
      <b>Банк филиаллари томонидан Кредит карта бериш тартиби</b>
    </div>
    <div>
      <table class="table">
        <thead>
        <tr>
          <th style="width: 6%">№</th>
          <th style="width: 30%">Шартлар</th>
          <th style="width: 64%">Қисқа таърифи</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="table-number">1</td>
          <td>Қабул қилган орган, Тартиб рақами ва санаси:</td>
          <td>Бошқарув қарори, 14.01.2020 й. №6/1</td>
        </tr>
        <tr>
          <td class="table-number">2</td>
          <td>Тушунчалар:</td>
          <td><b>Кредит карта</b> – алоҳида банк пластик карточкаси бўлиб, банк томонидан қўйилган лимит доирасида маблағ ишлатиш ҳуқуқини берувчи кредитлашнинг шакли.</td>
        </tr>
        <tr>
          <td class="table-number">3</td>
          <td>Кредит ажратилиши мумкин бўлган жисмоний шахслар:</td>
          <td>Банкларда миллий валютада пластик картаси мавжуд ҳамда доимий даромад манбаига <i>(ойлик иш ҳақи ва унга тенглаштирилган тўловлар, пенсия)</i> эга бўлган Ўзбекистон Республикаси фуқаролари.
          </td>
        </tr>
        <tr>
          <td class="table-number">4</td>
          <td>Кредит миқдори:</td>
          <td>Кредит карта орқали кредит маблағи <b>50 000 000 (эллик миллион)</b> сўмгача ажратилади. <b>(“Қарз юки” кўрсаткичи асосида)</b>.
            Кредит картаси фақат сўнги 6 ой давомида доимий даромадга эга бўлган фуқароларга берилади.
          </td>
        </tr>
        <tr>
          <td class="table-number">5</td>
          <td>Кредит муддати:</td>
          <td>4 йил (48 ой )Кредит ажратилган санадан бошлаб дастлабки 24 ойи (2 йил) давомида мижоз томонидан графикка асосан қайтарилган маблағдан қайта фойдаланиш (қайта лимит белгилаш) ва қолган 24 ой (2 йил) давомида лимитни пропорционал тартибда камайтириб бориш усули билан револьвер шаклда ажратилади.
          </td>
        </tr>
        <tr>
          <td class="table-number">6</td>
          <td>Фоиз ставкаси:</td>
          <td><b>27,99</b> фоизМижоз томонидан ишлатилган маблағ миқдорига ишлатилган кун учун фоиз ҳисобланади.</td>
        </tr>
        <tr>
          <td class="table-number">7</td>
          <td>Қарз олувчи томонидан тақдим этиладиган ҳужжатлар:</td>
          <td>7	Қарз олувчи томонидан тақдим этиладиган ҳужжатлар:		Кредит карта олиш ва кредит ажратиш тўғрисида ариза
            .	қарз олувчининг паспорт нусхаси;
            .	қуйидаги таъминот турларидан бири;
            - учинчи шахс кафиллиги;
            - кредит қайтмаслиги хатарини суғурта қилиш.
            - кредит сиёсати асосида ликвидли мол-мулк.
            Зарурат туғилганда қонунчиликликда белгиланган бошқа ҳужжатлар.
          </td>
        </tr>
        <tr>
          <td class="table-number">8</td>
          <td>Кредит ажратиш тамойиллари:</td>
          <td>8	Кредит ажратиш тамойиллари:	Кредит карта <b>қайтаришлик</b>, <b>тўловлилик</b>, <b>муддатлилик</b> ва <b>таъминланганлик</b> шартлари асосида ажратилади.</td>
        </tr>
        <tr>
          <td class="table-number">9</td>
          <td>Кредитнинг сўндирилиши:</td>
          <td>Кредитдан фойдаланиш давомида мижознинг пластик картасига келиб тушадиган маблағлар кредитнинг <b>асосий қарзи ва фоизларини сўндиришга йўналтирилади</b>.
            Графикка нисбатан ортиқча қайтарилган маблағ лимитга қайта қўшилади.
          </td>
        </tr>
        <tr>
          <td class="table-number">10</td>
          <td>Лимит тўхтатилиши</td>
          <td>Мижоз томонидан сўндирилиши лозим бўлган ойлик тўлов (график+фоиз) ўз вақтида тўланмаганда шу куннинг ўзида карта блокланади ва кейинги амалиёт тўхтатилади.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <credit-footer/>
</template>
<style>

.credit-procedure{
  margin-bottom: 14px;
  font-family: "Times New Roman";
  font-size: 18px;
}
.credit-procedure th, .table-number{
  text-align: center;
  flex-direction: column;
}
.credit-procedure table, th, td {
  border: 1px solid black;
}
.micro_credit_law{
  text-align: center;
  margin: 24px 0px 24px 0px;
}
</style>
