<template>
  <credit-header/>
  <div class="container credit-procedure">
    <div class="header_history_link" style="display: flex; justify-content: flex-start !important;">
      <router-link to="/education-credit-service" class="header_history_url_main">Кредит хизматлари</router-link>&nbsp;&nbsp;/&nbsp;&nbsp;
      <span class="header_history_url">Та'лим кредитлаш тартиби</span>
    </div>
    <div class="micro_credit_law">
      <b>Ўзбекистон Республикаси Президентининг 2021 йил 30 июлдаги “Олий таълим ташкилотларида таълим олиш имкониятларини янада кенгайтириш чора-тадбирлар” тўғрисидаги ПҚ-5203-сонли Қарорига асосан </b><br>
      <b>Таълим кредит ажратиш шартлари </b>
    </div>
    <div>
      <div class="row">
        <div class="col-1">
          <span>1.</span>
        </div>
        <div class="col-2">
          <b>Таълим кредити олувчи шахслар</b>
        </div>
        <div class="col-9">
          Республикадаги барча олий таълим ташкилотлари бакалавриатининг кундузги таълим шаклида ҳамда магистатура босқичида таҳсил олувчиларга.
          <i>(Табақалаштирилган  бирмарталик ва сиртқи бўлимларда ўқиш учун ажратилмайди).</i>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>2.</span>
        </div>
        <div class="col-2">
          <b>Ресурс манбаси ва жамғарма номи</b>
        </div>
        <div class="col-9">
          <span>Молия вазирлиги ҳузуридаги</span> <b>Таълим кредитини молиялаштириш жамғармаси</b>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>3.</span>
        </div>
        <div class="col-2">
          <b>Ресурс фоизи</b>
        </div>
        <div class="col-9">
          <span>Марказий банкнинг асосий ставкаси(14 фоиз)дан (2022 йил 31 декабрга қадар)</span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>4.</span>
        </div>
        <div class="col-2">
          <b>Таълим кредити миқдори</b>
        </div>
        <div class="col-9">
          <b>Таълим шартномасида кўрсатилган миқдорда</b> ажратилади.
          -Талабалар учун ўқув йили мабайнида ўқиш учун бир йиллик тўловнинг тўлиқ миқдорини белгиланган тартибда тенг улушларда тўрт қисмга бўлиш йўли билан ўтказилади.
          Бунда, белгиланган тўлов миқдорининг камида 25 фоизи талабаликка тавсия этилган (абитуриентлар) - 15 сентябргача ҳамда иккинчи ва ундан кейинги босқич талабалари учун-1 октябргача, белгиланган тўлов миқдорининг камида 50 фоизи -1 январгача, 75 фоизи-1 апрелгача ва 100 фоизи-1 июлга қадар ўтказиб берилади
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>5.</span>
        </div>
        <div class="col-2">
          <b>Куриб чиқиш муддати</b>
        </div>
        <div class="col-9">
          <span>Таълим кредити ажратиш юзасидан ариза <b>5 банк иш куни</b>да кўриб чиқилади.</span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>6.</span>
        </div>
        <div class="col-2">
          <b>Таълим кредити фоизи </b>
        </div>
        <div class="col-9">
          <span>Марказий банкнинг қайта молиялаш савкаси миқдорида <i>(14 фоизда 2022 йил охирига қадар).</i></span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>7.</span>
        </div>
        <div class="col-2">
          <b>Таълим кредити муддати</b>
        </div>
        <div class="col-9">
          <span>Ўқув юртини тамомлаганидан сўнг <b>еттинчи ойдан</b> бошлаб <b>7 йил давомида</b> асосий қарзни қайтариш шарти билан расмийлаштирилади. Бунда, кредит бўйича фоизлар кредит ажратилган даврдан бошлаб тўлаб борилади.</span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>8.</span>
        </div>
        <div class="col-2">
          <b>Кредит фоизи ўзгариши</b>
        </div>
        <div class="col-9">
          <span>Марказий банкнинг қайта молиялаш савкаси камайса кредит фоизи мутаносиб равишда камаяди, кўтарилса ўзгаришсиз қолади.</span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>9.</span>
        </div>
        <div class="col-2">
          <b>Тақдим этиладиган ҳужжатлар </b>
        </div>
        <div class="col-9">
          <span>-ариза;
               -паспорт нусхаси;
               -талаба ва олий таълим муассасаси ўртасидаги тўлов контракт шартномаси;
               -кредит қайтарилишининг таъминоти;
               -кафил ва биргаликда қарз олувчиларнинг паспорт нусхаси ва кредит сиёсатида белгиланган хужжатлар.
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>10.</span>
        </div>
        <div class="col-2">
          <b>Кредит таъминоти</b>
        </div>
        <div class="col-3">
          -мол-мулк гарови:
          .	Мулкни бахолаш далолатномаси;
          .	Мулкнинг хатар суғурта полиси;
          .	Гаров шартномаси;
        </div>
        <div class="col-3">
          -суғурта полиси:
          .	кредит қайтмаслигини суғурта қилинаганлиги тўғрисида
        </div>
        <div class="col-3">
          - учинчи шахс кафиллиги:
          .	паспорти ёки ID-картаси;
          .	доимий иш жойидан сўнгги 12 ой давомида олган ойлик даромади;
          .	кафиллик шартномаси
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>11.</span>
        </div>
        <div class="col-2">
          <b>Биргаликда қарз олувчилар</b>
        </div>
        <div class="col-9">
          <span>Таълим кредитини расмийлаштиришда ва унинг қайтарилишида талабанинг доимий даромад манбаига эга бўлган оила азолари биргаликда қарз олувчи сифатида қатнашиши мумкин. Бунда қуйидаги ҳужжатлар талаб этилади:
              .	паспорти ёки ID-картаси;
              .	доимий иш жойидан сўнгги 12 ой давомида олган ойлик даромади;
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>12.</span>
        </div>
        <div class="col-2">
          <b>Таваккалчиликни бошқариш</b>
        </div>
        <div class="col-9">
          <span>Юзага келиши мумкин бўлган таваккалчиликни ўзаро тақсимлаш мақсадида Молия вазирлиги жойлаштирилган маблағнинг 50 фоизини Жамғарма маблағлари ҳисобидан гаров депозитига қўяди.</span>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <span>13.</span>
        </div>
        <div class="col-2">
          <b>Муаммоли кредитлар қопланиши</b>
        </div>
        <div class="col-9">
          <span>Қарз очувчи томонидан асосий қарз ва (ёки) фоиз бўйича 180 кундан ошган муддати ўтган қарздорлик юзага келганда Молия вазирлиги таълим кредитнинг асосий қарзи қолдиғининг эллик фоизини қоплайди. Мижоз томонидан қарздорлик сўндирилгандан сўнг маблағнинг 50 фоизи миқдорида фонд олдидаги мажбурият сўндирилади.
          </span>
        </div>
      </div>
    </div>
  </div>
  <credit-footer/>
</template>
<style scoped>

@import '../assets/css/all.css';
.credit-procedure .row{
  margin-bottom: 14px;
}
.credit-procedure th, .table-number{
  text-align: center;
  flex-direction: column;
}
.credit-procedure .col-1, .col-2, .col-9, .col-3 {
  border: 1px solid black !important;
  margin-right: -1px;
}
.credit-procedure .row{
  margin-bottom: -1px;
}
.micro_credit_law{
  text-align: center;
  margin: 24px 0px 24px 0px;
}
</style>
