<template>
  <credit-header/>
  <div class="container about_user_header">
    <div class="header_history_link" style="display: flex; justify-content: flex-start !important;">
      <router-link to="/micro-credit-service" class="header_history_url_main">Кредит хизматлари</router-link>&nbsp;&nbsp;/&nbsp;&nbsp;
      <span class="header_history_url">Мижоз ҳақидаги тўлиқ маълумотлар</span>
    </div>
    <div class="row">
      <div class="col-4">
        Исми
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Фамиляси
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Отасининг исми
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Туғилган санаси
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Ҳужжат берилган Сана
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Ким томонидан берилган
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Қачон берилган
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        ПИНФЛ
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div><hr>
    <div class="row">
      <div class="col-4">
        Яшаш жойи
      </div>
      <div class="col-8">Мижоз ҳужжатларининг серия рақами тўғри киритилса мижоз ҳақидаги тўлиқ маълумотлар экранга ҳосил бўлади: Исми, Фамилияси,
        Отасининг исми, Туғилгани санаси, Хужжат берилган сана, Ким томонидан берилган, Қачон берилган, ПИНФЛ, Яшаш жойи.</div>
    </div>
  </div>
  <credit-footer/>
</template>
<script>
export default {

}
</script>
<style scoped>
@import '../assets/css/all.css';
.header_history_link{
  margin:24px 0px 44px 0px;
}
</style>