<template>
  <footer class="footer">
    <div class="footer_header"></div>
    <div class="footer_body">
      <div class="footer_body_content">
        <b> <span>2017-2022 © AKБ "Агробанк". Все права защищены.</span><br>
          <span>Все услуги лицензированы</span><br>
          <span>1216</span>
        </b>
      </div>
      <div class="footer_body_content">
        <a href="https://www.facebook.com/agrobankuzbekistan"><img src="../assets/icon/facebook.svg" alt=""></a>&nbsp;
        <a href="https://t.me/AgrobankChannel"><img src="../assets/icon/telegram.svg" alt=""></a>
      </div>
      <div class="footer_body_content">
        <a href="https://play.google.com/store/apps/details?id=uz.agrobank.mobile.mbank" class="footer_body_googleplay"><img src="../assets/icon/googleplay.png" alt=""></a>
      </div>
      <div class="footer_body_content">
        <a href="https://apps.apple.com/ru/app/agrobank-mobile-new/id1451292895" class="footer_body_googleplay"><img src="../assets/icon/appstore.png" alt=""></a>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name:'creditFooter'
  }
</script>
<style scoped>
@import '../assets/css/all.css';
@import '../assets/css/bootstrap.min.css';

</style>