<template>
    <router-view/>
</template>
<script>

</script>
<style scoped>
@import './assets/css/all.css';
@import './assets/css/bootstrap.min.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
